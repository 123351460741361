import {
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Avatar,
  Box,
  ButtonGroup,
  Divider,
  useTheme,
  Badge,
  useMediaQuery,
} from "@mui/material";
import React, { useRef } from "react";
import {
  ChatBubbleOutline,
  MoreHoriz,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import {
  IApprovalItemComment,
  IApprovalItemCommentAicByEnum,
  IApprovalItemRevision,
} from "../services/thumbz-base-api";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  approvalSlice,
  approveItem,
  reproveItem,
} from "../features/approvals/approvalsSlice";
import Slider, { Settings } from "react-slick";
import { RenderImgPreview } from "./render-img-preview";
import { CustomCarousel } from "./custom-carousel";
import { RichTextRenderer } from "./richtext/RichTextRender";
import { ApprovalTypeFields } from "./responses/approval-type-fields";
import { Stack } from "@mui/system";
import { FieldLabel } from "./field-label";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface IRevisionCardProps {
  revision: IApprovalItemRevision;
}

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  className: "inner-carousel",
};

export const RevisionItem: React.FC<IRevisionCardProps> = ({ revision }) => {
  const sliderRef = useRef<Slider>(null);
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));
  const workspace = useAppSelector(approvalSlice.selectors.selectWorkspace);
  const dispatch = useAppDispatch();
  const selectorItemStatus = useAppSelector(
    approvalSlice.selectors.selectItemStatus
  );
  const revisionCommentsCount = useAppSelector(
    approvalSlice.selectors.revisionCommentsCount
  );
  const selectItemResponses = useAppSelector(
    approvalSlice.selectors.selectItemResponses
  );
  const nextRevision = useAppSelector(
    approvalSlice.selectors.selectNextPendingRevision
  );

  function handleApprove() {
    dispatch(approveItem({ air_id: revision.air_id }))
      .unwrap()
      .then((response) => {})
      .catch((error) => {
        console.error("Erro ao aprovar o item:", error);
      });
  }

  const isCarrossel = revision?.approvalitem?.approvalItemAsset.length > 1;

  function handleOpenChat() {
    dispatch(approvalSlice.actions.openChat());
  }

  function handleReprove() {
    dispatch(reproveItem({ air_id: revision.air_id }));
  }

  const RenderButtons = () => {
    const { is_approved, is_pending, is_reproved } = selectorItemStatus(
      revision.air_id
    );

    let reprove_color: string = theme.palette.error.main;
    let approve_color: string = theme.palette.success.main;

    if (is_reproved) {
      reprove_color = theme.palette.error.main;
      approve_color = theme.palette.grey[500];
    } else if (is_pending) {
    } else if (is_approved) {
      reprove_color = theme.palette.grey[500];
      approve_color = theme.palette.success.main;
    }

    return (
      <ButtonGroup fullWidth variant="text" size="small" color="inherit">
        <Button
          // color={is_reproved ? "error" : ""}
          style={{
            color: reprove_color,
          }}
          startIcon={<ThumbDown />}
          onClick={() => handleReprove()}
        >
          Solicitar ajuste
        </Button>
        <Button
          style={{
            color: approve_color,
          }}
          onClick={handleApprove}
          endIcon={<ThumbUp />}
        >
          Aprovar
        </Button>
      </ButtonGroup>
    );
  };

  return (
    <Card
      elevation={10}
      sx={{
        borderRadius: 0.5,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* ider do Card: Similar ao Instagram */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 1,
          py: 0.5,
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Avatar
          sx={{ width: 32, height: 32, mr: 2 }}
          src={workspace?.wrk_photo?.ast_url ?? ""}
        />
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {workspace?.wrk_name}
        </Typography>
        <IconButton disabled>
          <MoreHoriz />
        </IconButton>
      </Box>

      <Box bgcolor={theme.palette.grey[500]}>
        {isCarrossel ? (
          <CustomCarousel key={revision.air_id}>
            {revision?.approvalitem?.approvalItemAsset.map((asset, idx) => (
              <Zoom>
                <RenderImgPreview
                  showThumbnail={false}
                  height={"50vh"}
                  approvalItemAsset={asset}
                />
              </Zoom>
            ))}
          </CustomCarousel>
        ) : (
          <Zoom>
            <RenderImgPreview
              height={"50vh"}
              showThumbnail={false}
              approvalItemAsset={revision?.approvalitem?.approvalItemAsset[0]}
            />
          </Zoom>
        )}
      </Box>

      {/* Conteúdo do Card */}
      <CardContent
        style={{
          paddingTop: 5,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Stack
          spacing={1}
          alignContent={"flex-start"}
          alignItems={"flex-start"}
          alignSelf={"flex-start"}
        >
          <Button
            onClick={handleOpenChat}
            startIcon={
              <Badge badgeContent={revisionCommentsCount} color="primary">
                <ChatBubbleOutline color="action" />
              </Badge>
            }
            style={{ paddingLeft: 0 }}
          >
            <Typography variant="body2" color={"GrayText"}>
              Deixar comentário
            </Typography>
          </Button>

          {selectItemResponses.length > 0 && (
            <ApprovalTypeFields responses={selectItemResponses} />
          )}

          <Stack>
            <FieldLabel label="Descrição" />
            <RichTextRenderer
              content={
                revision.approvalitem.item_description ||
                "Nenhuma descrição informada"
              }
            />
          </Stack>
        </Stack>
      </CardContent>

      {/* Botões fixados no rodapé do card */}
      <Divider />
      <RenderButtons />
    </Card>
  );
};

function customerOrUserToCommon(comment: IApprovalItemComment): {
  name: string;
  photo: string;
} {
  if (comment.aic_by === IApprovalItemCommentAicByEnum.Customer) {
    return {
      name: comment.customer?.cust_name || "",
      photo: comment.customer?.cust_photo?.ast_url || "",
    };
  }

  return {
    name: comment.user?.usu_name || "",
    photo: comment.user?.usu_photo?.ast_url || "",
  };
}
