import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { IApprovalItemAsset } from "../services/thumbz-base-api";
import { getMimetype, Mimetype } from "../utils/get-mimetype";
import { PictureAsPdf } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ResponsiveImg } from "./responsive-img";

interface IRenderImgPreviewProps {
  approvalItemAsset: IApprovalItemAsset;
  showThumbnail?: boolean;
  height?: string | number;
}

export const RenderImgPreview: React.FC<IRenderImgPreviewProps> = React.memo(
  ({ approvalItemAsset, showThumbnail = true, height = 250 }) => {
    const { ast_url, ast_mimetype, ast_original_filename } =
      approvalItemAsset.asset;
    const mimeType = useMemo(() => getMimetype(ast_mimetype), [ast_mimetype]);
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const prevThumbnail = useRef<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [videoSrc, setVideoSrc] = useState<string | null>(ast_url);
    const thumbnailCache = useRef(new Map<string, string>()); // Cache em memória

    // Busca no cache (localStorage ou memória)
    const getCachedThumbnail = useCallback((videoUrl: string) => {
      return (
        localStorage.getItem(`thumbnail-${videoUrl}`) ||
        thumbnailCache.current.get(videoUrl) ||
        null
      );
    }, []);

    // Captura o thumbnail e evita flickering
    const captureThumbnail = useCallback(
      (videoUrl: string) => {
        const cachedThumb = getCachedThumbnail(videoUrl);
        if (cachedThumb) {
          // Mantém o antigo visível até atualizar
          requestAnimationFrame(() => {
            prevThumbnail.current = cachedThumb;
            setThumbnail(cachedThumb);
          });
          return;
        }

        const video = document.createElement("video");
        video.crossOrigin = "anonymous";
        video.src = videoUrl;
        video.currentTime = 1;

        video.onloadeddata = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");

          if (context) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            try {
              const thumbData = canvas.toDataURL("image/png");

              // Atualiza com leve delay para suavizar a transição
              requestAnimationFrame(() => {
                prevThumbnail.current = thumbData;
                setThumbnail(thumbData);
              });

              localStorage.setItem(`thumbnail-${videoUrl}`, thumbData);
              thumbnailCache.current.set(videoUrl, thumbData);
            } catch (error) {
              console.error("CORS issue with thumbnail:", error);
            }
          }
        };
      },
      [getCachedThumbnail]
    );

    // Atualiza vídeo sem desmontar
    useEffect(() => {
      if (ast_url && mimeType === Mimetype.Video) {
        if (videoRef.current) {
          videoRef.current.src = ast_url;
        }
        setVideoSrc(ast_url);
        captureThumbnail(ast_url);
      }
    }, [ast_url, mimeType, captureThumbnail]);

    // Memoized rendering logic
    const renderMedia = useMemo(() => {
      if (!ast_url) return <p>Unsupported media type</p>;

      switch (mimeType) {
        case Mimetype.Image:
          return (
            <ResponsiveImg
              url={ast_url}
              alt={ast_original_filename ?? "Imagem"}
              maxHeight={height}
            />
          );
        case Mimetype.Video:
          return showThumbnail ? (
            <ResponsiveImg
              url={thumbnail || prevThumbnail.current || ast_url}
              alt={ast_original_filename ?? "Vídeo"}
              maxHeight={height}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height,
                overflow: "hidden",
              }}
            >
              <video
                key="persistent-video"
                ref={videoRef}
                controls={!showThumbnail}
                autoPlay={false}
                src={videoSrc || ""}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  pointerEvents: showThumbnail ? "none" : "auto",
                }}
              />
            </div>
          );
        case Mimetype.Pdf:
          return showThumbnail ? (
            <Avatar variant="square" style={{ width: "100%", height }}>
              <Stack
                direction="column"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <PictureAsPdf color="info" />
                <Typography variant="caption" color="black">
                  {ast_original_filename ?? "PDF"}
                </Typography>
              </Stack>
            </Avatar>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height,
                overflow: "hidden",
              }}
            >
              <iframe src={ast_url} width="100%" height="100%" />
            </div>
          );
        default:
          return <p>Unsupported media type</p>;
      }
    }, [mimeType, ast_url, ast_original_filename, showThumbnail, thumbnail]);

    return renderMedia;
  }
);

export default RenderImgPreview;
